import { inject, Ref } from "vue"
import { OnboardingFormValues } from "./types"

export const useOnboardingContext = (): {
  onboardingForm: Ref<OnboardingFormValues>
  updateOnboardingForm: (newOnboardingForm: Partial<OnboardingFormValues>) => void
} => {
  const onboardingForm = inject("onboardingForm") as Ref<OnboardingFormValues>

  if (!onboardingForm.value) {
    throw new Error("Form must be provided")
  }

  const updateOnboardingForm = (newOnboardingForm: Partial<OnboardingFormValues>) => {
    onboardingForm.value = { ...onboardingForm.value, ...newOnboardingForm }
  }

  return { onboardingForm: onboardingForm, updateOnboardingForm }
}
